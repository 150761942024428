<template>
  <div class="mobile-answer-sheet">
    <el-tabs v-model="answerQuestionViewMode" :stretch="true"  @tab-click="mobileViewModeChange">
      <el-tab-pane label="回答" :name="ANSWER_QUESTION_VIEW_MODEL.ONLY_ANSWER_SHEET" ></el-tab-pane>
      <el-tab-pane  :name="ANSWER_QUESTION_VIEW_MODEL.ONLY_DOC_PREVIEW" >
        <template #label>
          <span>预览<span class="preview-badge" v-if="totalUnreadRelatedCount>0">{{totalUnreadRelatedCount}}</span></span>
        </template>
      </el-tab-pane>
    </el-tabs>

    <main class="content-container">
      <aside class="answerSheet-area content-area" v-show="answerQuestionViewMode ===  ANSWER_QUESTION_VIEW_MODEL.ONLY_ANSWER_SHEET">
        <doc-questions ref="question"
                       :file-id="fileId" :draft-contract-mode="draftContractMode"
                       :leftWidth="leftWidth"
                       :getAssociationQuestionFunc="getAssociateQuestion"
                       :getAssociationVisibleControlQuestion="getAssociationVisibleControlQuestion"
                       :defaultMode="activeMode" :deleteQuestionRelatedArea="deleteQuestionRelatedArea"
                       @answer-changed="answerChanged"
                       @answer-all-question="answerAllQuestion" @refresh-editor-questions="refreshEditorQuestions"
                       @question-answer-info-changed="questionAnswerInfoChanged"
                       @mode-changed="modeChange"
                       :highlightEditorQuestionRelatedRange="highlightEditorQuestionRelatedRange"
                       :showVariable="variableConfig.showVariable"
                       @question-option-changed="questionOptionChanged"/>
      </aside>
      <aside class="editor-area content-area" v-show="answerQuestionViewMode ===  ANSWER_QUESTION_VIEW_MODEL.ONLY_DOC_PREVIEW">
        <doc-content ref="content"
                     @editor-messages="handelEditorMessages" @editor-content-changed="editorContentChanged"
                     @editor-ready="editorInitReady"
                     :getSetting="getSetting"
                     :fileId="fileId" :content="content"
                     :variableConfig="variableConfig"
                     :defaultMode="activeMode"/>
      </aside>
    </main>
  </div>
</template>

<script>
import {
  ACTIVE_MODEL,
  ANSWER_QUESTION_VIEW_MODEL,
  convertContentToStringIFNotNull,
  EditorMsgType
} from "../../../constant/question";
import DocContent from "../../../components/DocContent";
import {ElLoading, ElMessage} from "element-plus";
import {canTakeTextAsDefaultAnswer} from "../../../components/common/question";
import {appPostMessage, colorLog} from "../../../util";
import {
  activeHiddenTemplateFile,
  draftContractByAnswers,
  getTemplateFileMeta,
  requestHeaderMixin
} from "../../../api/api";
import DocQuestions from "../../../components/DocQuestions";
import {ContractApprovalStatus} from "../../../constant/contract";
import {globalTagClick} from "../../../assets/js/tag/tag";

export default {
  name: "MobileDocTemplate",
  props: ['fileId', 'content', 'mode','templateId'],
  mixins:[requestHeaderMixin],
  components: {DocQuestions, DocContent},
  data(){
    return {
      groups: [],
      groupQuestions: [],
      activeGroups: [],
      currentMode:'edit_model',
      loadingInstance: null,
      checkContentSaverInterval: null,
      previewActiveGroups: [],
      variableConfig: {
        showVariable: true
      },
      activeMode:this.mode || ACTIVE_MODEL.EDIT_MODEL,
      ANSWER_QUESTION_VIEW_MODEL: ANSWER_QUESTION_VIEW_MODEL,
      answerQuestionViewMode: ANSWER_QUESTION_VIEW_MODEL.ONLY_ANSWER_SHEET,
      draftContractMode:false,
      leftWidth:window.innerWidth,
      // 未读的答案改变造成的区域变化的个数的map
      unreadAnswerChangedRelatedRectMap:new Map(),
    }
  },
  watch:{
    'answerQuestionViewMode':function () {
      this.unreadAnswerChangedRelatedRectMap.clear();
    },
  },
  computed:{
    totalUnreadRelatedCount:function () {
      let totalUnreadRelatedCount = 0;
      this.unreadAnswerChangedRelatedRectMap.forEach((val) => {
        totalUnreadRelatedCount += val;
      })
      return totalUnreadRelatedCount;
    }
  },
  methods:{
    createContract:function(){

      let {answers} = this.getAnswersParam();
      this.loadingInstance = ElLoading.service({ fullscreen: true, body: true, lock: true, spinner: 'el-icon-loading' });
      this.loadingInstance.setText('创建中')
      draftContractByAnswers(answers, this.templateId).then(res => {
        if (res.data.code === 0) {
          globalTagClick('template-create-contract-draft');
          this.loadingInstance.setText('创建成功')
          this.$message.success('创建合同成功');
          let contract = res.data.data;

          appPostMessage({contract,msg:'创建成功',code:0});
        } else if (res.data.code === 401) {
          appPostMessage({msg:'无权限',code:1});
          this.$message.error('暂无权限，请在当前浏览器登录后操作');
        } else {
          appPostMessage({msg:'创建失败',code:2});
          this.$message.error('创建合同失败，请稍后再试');
        }
      }).catch(error => {
        appPostMessage({msg:'创建失败',code:2});
        console.error(error);
        this.$message.error('创建合同失败，请稍后再试');
      }).finally(() => {
        this.loadingInstance.close()
      });
    },
    getAnswersParam: function () {
      const answers = [], oldAnswerMap = new Map();

      this.$refs.question.answerSheet.forEach(as => {
        const copyAnswer = Object.assign({}, as.answer);
        copyAnswer.content = convertContentToStringIFNotNull(copyAnswer.content);
        answers.push(copyAnswer);
        oldAnswerMap.set(as.question.id, as.answer);
      });
      return {answers:answers, oldAnswerMap: oldAnswerMap}
    },
    mobileViewModeChange(...args){
    },
    handelEditorMessages: function (msg) {
      // console.log('received editor msg**********');
      // console.log(msg)
      for (let i = 0; i < msg.length; i++) {
        this.handelEditorMessage(msg[i]);
      }
    },
    handelEditorMessage: function (msg) {
      if (msg.action === EditorMsgType.ADD) {
        this.newQuestionAndLink(msg);
      } else if (msg.action === EditorMsgType.LINK) {
        this.linkQuestion(msg);
      } else if (msg.action === EditorMsgType.QUESTION_LIST_ACCESS) {
        this.questionListAccess(msg);
      } else if (msg.action === EditorMsgType.QUESTION_ACCESS) {
        this.questionAccess(msg)
      } else if (msg.action === EditorMsgType.FOCUS_QUESTION) {
        this.$refs.question.focusQuestionByUUID(msg.params.questionUUID);
      } else if (msg.action === EditorMsgType.ADD_OR_LINK) {
        if (!this.checkBeforeLink(msg)) {
          return
        }
        this.$refs.question.openAddOrLinkQuestionDialog(msg.params, msg.callback);
      } else if (msg.action === EditorMsgType.SHOW_MESSAGE) {
        ElMessage({
          showClose: true,
          message: msg.params.message,
          type: msg.params.type
        });
      }
    },
    newQuestionAndLink: function (msg) {
      if (!this.checkBeforeLink(msg)) {
        return
      }
      // let defaultVal = {answerType: msg.params.type, title: msg.params.text};
      //todo msg.params.text需要作为预览模式该问题的默认答案。
      let defaultVal = {answerType: msg.params.type};
      //如果是大区域里面画小区域，则将该小区域的依赖问题的id
      if (msg.params.parentQuestionUUID) {
        defaultVal.showControlAnsId = this.$refs.question.getIdByUUID(msg.params.parentQuestionUUID)
      }
      this.$refs.question.newAndSaveQuestion(defaultVal).then((newQuestion) => {
        // console.log('newAndSaveQuestion  newQuestion:', newQuestion);
        if (canTakeTextAsDefaultAnswer(newQuestion)) {
          //如果是文本类型的话，就把关联的文本作为默认的答案
          // this.$refs.question.insertAnswerCache(newQuestion, msg.params.text);
          this.emptyQuestion.defaultAnswer = this.addOrLinkParam.text
        }
        //如果是小区域外面画大区域
        this.updateChildrenRelatedQuestion(msg, newQuestion.uuid);
        if (msg.callback) {
          msg.callback({code: 0, msg: "成功", data: newQuestion})
        }
      }).catch(err => {
        if (msg.callback) {
          msg.callback({code: -1, msg: "新建问题失败"})
        }
      });
    },
    linkQuestion: function (msg) {
      if (!this.checkBeforeLink(msg)) {
        return
      }
      //如果是大区域里面画小区域，则将该小区域的依赖问题的id
      if (msg.params.parentQuestionUUID) {
        this.$refs.question.updateDefaultRelatedQuestion(msg.params.questionUUID, msg.params.parentQuestionUUID);
      }

      setTimeout(() => {
        let question = this.$refs.question.findItemByUUID(msg.params.questionUUID)
        this.$refs.question.focusQuestionByUUID(msg.params.questionUUID);
        //如果是小区域外面画大区域
        this.updateChildrenRelatedQuestion(msg, msg.params.questionUUID)
        if (msg.callback) {
          console.log('linkQuestion***8')
          msg.callback({code: 0, msg: "成功", data: question})
        }
      }, 100)
    },
    //将目前所有的问题返回给editorJs
    questionListAccess: function (msg) {
      if (msg.callback) {
        let items = this.$refs.question.getItems().map(item => {
          // return {answerName: item.answerName, uuid: item.uuid, answerType: item.answerType}
          return item;
        })
        // console.log(items)
        msg.callback(items);
      }
    },
    questionAccess: function (msg) {
      if (msg.callback) {
        const question = this.$refs.question.findItemByUUID(msg.params.uuid);
        msg.callback(question);
      }
    },
    checkBeforeLink: function (msg) {
      //如果是小区域外面画大区域，则将小区域的问题的依赖问题设置为大区域的问题
      if (msg.params.childQuetionsUUID) {
        for (let i = 0; i < msg.params.childQuetionsUUID.length; i++) {
          //判断该小区域说关联的问题是否已经有依赖问题了。
          let hasRelatedQuestion = this.$refs.question.checkQuestionHasRelatedQuestion(msg.params.childQuetionsUUID[i]);
          if (hasRelatedQuestion) {
            //如果有依赖问题，则不允许它新建问题
            if (msg.callback) {
              msg.callback({code: -1, msg: "更新依赖问题失败，该问题已有依赖"})
            }
            return false
          }
        }
      }
      return true
    },
    updateChildrenRelatedQuestion(msg, relatedUUID) {
      if (msg.params.childQuetionsUUID) {
        for (let i = 0; i < msg.params.childQuetionsUUID.length; i++) {
          this.$refs.question.updateDefaultRelatedQuestion(msg.params.childQuetionsUUID[i], relatedUUID);
        }
      }
    },
    editorContentChanged: function () {
      if (this.$refs['question'] && this.$refs['question'].editorContentChanged) {
        this.$refs['question'].editorContentChanged();
      }
    },
    editorInitReady: function () {
      // if (this.activeMode === ACTIVE_MODEL.ANSWER_MODEL) {
      //     this.$refs['question'].createAnswerSheet();
      // }
      if (this.$refs['question'] && this.$refs['question'].editorInitReady) {
        this.$refs['question'].editorInitReady();
      }
    },
    getSetting: function () {
      return this.$refs['question']['setting'];
    },
    getAssociateQuestion: function () {
      return this.$refs['content'].getAssociateQuestion().map(item => item.uuid)
    },
    getAssociationVisibleControlQuestion: function () {
      return this.$refs['content'].getAssociationVisibleControlQuestion()
    },
    deleteQuestionRelatedArea: function (params) {
      this.$refs['content'].deleteQuestionRelatedArea(params)
    },
    answerChanged: function (answerSheet) {
      this.answerSheet = answerSheet;
      let res  = this.$refs['content'].answerChanged(answerSheet.answer.questionUUID, answerSheet.answer.answer.content, answerSheet.involvedQuestionVisible, answerSheet.scrollToRect);
      this.recordAnswerChangedRelatedRect(answerSheet.answer.questionUUID, res.count);
    },
    recordAnswerChangedRelatedRect: function (questionUUID,count) {
      if (this.answerQuestionViewMode === ANSWER_QUESTION_VIEW_MODEL.ONLY_ANSWER_SHEET) {
        this.unreadAnswerChangedRelatedRectMap.set(questionUUID, count);
        window.navigator.vibrate(100);
      }
    },
    answerAllQuestion: function (answers) {
      this.$refs['content'].answerAllQuestion(answers);
    },
    refreshEditorQuestions: function (questions) {
      this.$refs['content'].refreshQuestions(questions);
    },
    questionAnswerInfoChanged: function (question) {
      // console.log('notify editorJS variable changed')
      this.$refs['content'].questionVariableChanged(question)
    },
    modeChange: function (mode) {
      if (mode === this.oldActiveModel || this.loadingInstance !== null) {
        return false;
      }

      /**
       * 每次切换到编辑模式时，调用激活隐藏文件接口
       * 接口会自行判断是否需要激活
       */
      if (mode === 'edit_model') {
        activeHiddenTemplateFile({fileId: this.fileId});
      }

      this.activeMode = mode;
      this.oldActiveModel = mode;
      if (this.$refs['content'].saver) {
        this.loadingInstance = ElLoading.service({
          fullscreen: true,
          body: true,
          lock: true,
          spinner: 'el-icon-loading'
        });
        console.log('等待保存中！！！！！！');
        this.checkContentSaverInterval = setInterval(() => {
          if (!this.$refs['content'].saver) {
            console.log('！！！保存结束，开始切换模式！！！！！');
            clearInterval(this.checkContentSaverInterval);
            this.doModeChange(mode);
          }
        }, 1000);
      } else {
        this.loadingInstance = ElLoading.service({
          fullscreen: true,
          body: true,
          lock: true,
          spinner: 'el-icon-loading'
        });
        this.doModeChange(mode);
      }
    },
    highlightEditorQuestionRelatedRange: function (questionUUID, twinkle, directory, index) {
      return this.$refs['content'].highlightQuestion(questionUUID, twinkle, directory, index);
    },
    questionOptionChanged: function (params) {
      this.$refs['content'].questionOptionChanged(params['questionId'], params['oldName'], params['newName']);
    },
    doModeChange: function (mode) {
      this.$refs['question'].activeModelSwitched(mode);
      setTimeout(() => {
        this.$refs['content'].modeChange(mode, () => {
          if (mode === ACTIVE_MODEL.ANSWER_MODEL) {
            this.$refs['question'].answerAllQuestion(() => {
              this.loadingInstance.close();
              this.loadingInstance = null;
            });
          } else {
            this.loadingInstance.close();
            this.loadingInstance = null;
          }
          // setTimeout(() => { this.loadingInstance.close(); }, 500);
        });
      }, 200);
    },
    init: function (fileId) {
      this.$refs.question.init(fileId);
    },
  },
  mounted: function () {
    window.receiveMessage = (msg) => {            //在window上挂载一个receiveMessage方法，RN会调用
      let operation = msg['type'];
      if(operation == 'publish'){
        this.createContract();
      }
    }

    if(this.mode){
      this.currentMode=this.mode;
    }
    if(this.templateId){
      this.draftContractMode=true;
    }
    this.init(this.fileId);
    getTemplateFileMeta(this.fileId).then(res => {
      if (res.data.code === 0) {

        const data = res.data.data
        if (data.isPublished) {
          this.publishedTemplate = data.publishedTemplate;
          this.publishedTemplateId = this.publishedTemplate.id;

          this.$refs.editTitle.title = data.title.replace(/.pro$/, '');
          this.$nextTick(() => {
            this.$refs.editTitle.upgradeWidthWithScaler();
          })
        }
      } else {
        console.error('getFileTemplatePublish  err:'+res.data.code);
      }
    }).catch(err => {
      console.error('getFileTemplatePublish  err:', err);
    })
  },
}
</script>
<style scoped>
.mobile-answer-sheet >>> .el-tabs__header{
  margin:0;
}
.mobile-answer-sheet >>> .leftMenu{
  width: 100%;
}
.mobile-answer-sheet >>> #answerModeScroller{
  height: calc(100vh - 40px) !important;
  width: 100%;
}
.mobile-answer-sheet >>> .leftScroller{
  padding-right: 0 !important;
}
.mobile-answer-sheet >>> .el-tabs__item{
  position:relative;
}
.preview-badge{
  position:absolute;
  display: inline-block;
  line-height: 1;
  width: 1em;
  height: 1em;
  text-align: center;
  padding: 2px;
  border-radius: 100px;
  top: .5em;
  color:#fff;
  background: var(--lightBlue);
}

</style>
<style scoped lang="scss">

</style>
